import "./content-section.css";

export enum BackgroundType {
  Grey = "grey-section",
  White = "white-section",
}

interface ContentSectionProps {
  label: string;
  content: string;
  hasMargin: boolean;
  background: BackgroundType;
}

export const ContentSection = ({
  label,
  content,
  hasMargin,
  background,
}: ContentSectionProps) => (
  <section
    className={`content-section ${background} ${
      hasMargin ? "content-section-margin" : ""
    }`}
  >
    <div className="content-section-container">
      <h2>{label}</h2>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </section>
);
