import { About, Contact, Dogs, GermanShephred, Home, Puppies } from "./pages";

interface RoutingTypes {
  label: string;
  path: string;
  component: any;
}

export const Routing: RoutingTypes[] = [
  { label: "start", path: "/", component: <Home /> },
  {
    label: "hodowla",
    path: "/hodowla-z-ksiazenickiego-wzgorza",
    component: <About />,
  },
  {
    label: "Galeria",
    path: "/galeria-psow-z-ksiazenickiego-wzgorza",
    component: <Dogs />,
  },

  {
    label: "rasa",
    path: "/o-rasie-owczarek-niemiecki",
    component: <GermanShephred />,
  },
  {
    label: "szczeniaki",
    path: "/szczeniaki-owczarka-niemieckiego-na-sprzedaz-z-kiazenickiego-wzgorza",
    component: <Puppies />,
  },
  { label: "kontakt", path: "/kontakt", component: <Contact /> },
];
