import { Footer, Gallery, Map, Navigation } from "../../components";
import "./dogs.css";

export const Dogs = () => {
  return (
    <>
      <Navigation />
      <section className="dogs-section dogs-section-margin">
        <div className="dogs-section-container">
          <h2>Nasza hodowla</h2>
          <Gallery />
        </div>
      </section>
      <Map />
      <Footer />
    </>
  );
};

export default Dogs;
