import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Routing } from "./routing";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {Routing.map((item, index) => (
            <Route
              path={item.path}
              key={index}
              Component={() => item.component}
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
