import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { navigationLinks } from "./navigation.data";
import logo from "../../assets/logo.png";
import "./navigation.css";

export const Navigation = () => {
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(location.pathname !== "/");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname !== "/") return;
      const currentScrollPosition = window.scrollY;
      const shouldExecuteAction = currentScrollPosition > 100;
      if (shouldExecuteAction && !isScrolled) {
        setScrolled(true);
      } else if (!shouldExecuteAction && isScrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  return (
    <header className={isScrolled ? "site-scrolled" : ""}>
      <div className="nav-container">
        <a href="/" className="nav-container-logo">
          <img src={logo} alt="logo" />
        </a>
        <div
          onClick={toggleMenu}
          className={`toggler ${menuOpen && "toggler-active"}`}
        >
          <span />
          <span />
          <span />
        </div>
        <nav className={menuOpen ? "nav-active" : ""}>
          {navigationLinks.map((item, index) => (
            <a className="nav-container-link" href={item.path} key={index}>
              {item.label}
            </a>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Navigation;
