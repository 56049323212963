import { BackgroundType, Button, ContentSection } from "../../ui";
import { Footer, Map, Navigation } from "../../components";
import organisationLogo from "../../assets/zhpr.png";
import "./home.css";

export const Home = () => (
  <>
    <Navigation />
    <div className="hero-section">
      <Button
        label="Zarezerwuj szczeniaka"
        path="/szczeniaki-owczarka-niemieckiego-na-sprzedaz-z-kiazenickiego-wzgorza"
      />
    </div>
    <ContentSection
      label="Jak to wszystko się zaczęło?"
      background={BackgroundType.White}
      hasMargin={false}
      content={`<p>Od dziecka interesowałem się psami. Moim marzeniem było mieć pięknego
        owczarka niemieckiego. Pierwszego owczarka, sukę z rodowodem, kupiłem w
        1995 roku. Suka wabiła się Diana. Jest inteligentna, ma twardy
        charakter. Na podstawie obserwacji zachowań Diany doszedłem do wniosku,
        że owczarki niemieckie to jedne z najinteligentniejszych psów. Z czasem
        w wyniku zafascynowania rasą postanowiłem założyć hodowlę własną hodowlę
        by móc uszczęśliwiać ludzi przekazując im jak najwspanialsze, wierne,
        oddane i inteligentne szczeniaki. Wstąpiłem do związku kynologicznego,
        gdzie moja hodowla otrzymała przydomek "Z Książenickiego Wzgórza".
        Poznałem tam wielu znakomitych hodowców i nabierałem od nich cennego
        doświadczenia w hodowaniu psów, które pozwala mi w tym momencie na
        uzyskiwanie zdrowych, silnych psów o nienagannym rodowodzie, które
        towarzyszom moim klientom przez całe swoje życie.</p>`}
    />
    <ContentSection
      label="Jak wygląda moja hodowla?"
      background={BackgroundType.Grey}
      hasMargin={false}
      content={`<p>Po utworzeniu przeze mnie hodowli z czasem owczarek niemiecki zyskał ogromną popularność 
        co pozwoliło mi na jeszcze lepsze zgłębienie tematu hodowli. Psy rozmnażane są z zachowaniem jak
        największej poprawności i zasad krynologicznych. Obecnie koncenrtuje się na owczarkach niemieckich 
        długowłosych, czarnych, podpalanych. Moja hodowla bazuje na psach rodowodowych których rodzice klasyfikują
        się w pierwszej piątce (do VA5) wystaw Światowych.<p>                     
      <ul>Nasze psy mają tak znanych przodków jak:
           <li>"Zamp Thermodos" - mistrz świata 2006r,</li>
            <li>"Odin v. Holtkämper Hof" - wicemistrz świata 2008r,</li>
            <li>"Vegas du Haut Mansard" - mistrz świata 2009r,</li>
            <li>"Yerom Haus Salihin" - wicemistrz świata 2009r,</li>
            <li>"Kwantum Klostermoor" - czwarte miejsce w roku 2009,</li>
            <li>"Remo Fichtenschlag" - mistrz świata 2011r,</li>
            <li>"Nero v. Hirschel" - mistrz świata,</li>
            <li>"Zamb v.Wienerau" - również mistrz świata.</li>
           </ul> `}
    />
    <div className="booked-dog-section">
      <h2>Czujesz, że jesteś gotowy na szczeniaka?</h2>
      <Button
        label="Zarezerwuj szczeniaka"
        path="/szczeniaki-owczarka-niemieckiego-na-sprzedaz-z-kiazenickiego-wzgorza"
      />
    </div>
    <div className="organisation-section">
      <div className="organisation-section-container">
        <img src={organisationLogo} alt="zwiazek hodowcow psow rasowych" />
        <h2>Należymy do Związku Hodowców Psów Rasowych</h2>
        <p>
          Związek ZHPR to jeden z najbardziej prestiżowych i najstarszych
          związków hododowlanych w Polsce. Przynależność do niego gwarantuje, że
          psy są pod stałym nadzorem weterynarza i nie odbiegają od przyjętej
          normy, a przede wszystkim są przetrzymywane w świetnych warunkach,
          które nie wpływają negatywnie na ich rozwój czy też kondycje
          psychiczną i fizyczną.
        </p>
      </div>
    </div>
    <Map />
    <Footer />
  </>
);

export default Home;
