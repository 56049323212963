import "./footer.css";

export const Footer = () => (
  <footer>
    <div className="footer-container">
      <div className="footer-container-box">
        <h2>Kontakt:</h2>
        <ul>
          <li>Telefon: 516 052 576, 32 42 19 725</li>
          <li>Adres: ul. Klimka 34, 44-213 Książenice</li>
          <li>
            Email:
            <a href="mailto:mkondzielnik@poczta.onet.pl">
              mkondzielnik@poczta.onet.pl
            </a>
          </li>
          <li>
            Facebook:
            <a
              href="https://www.facebook.com/zksiazenickiegowzgorza"
              target="_blank"
              rel="noopener noreferrer"
            >
              zksiazenickiegowzgorza
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-container-box">
        <h2>Rezerwacje:</h2>
        <p>
          W celu rezerwacji szczeniąt prosimy o telefon pod numerem 516-052-576.
          Zaliczki prosimy wpłacać na konto w PKO:
          <br />
          <br />
          <strong>Mirosław Kondzielnik</strong>
          <br />
          Numer konta: 85 1020 2472 0000 6802 0051 7813
        </p>
      </div>
      <div className="footer-container-box">
        <h2>Zapłata:</h2>
        <p>
          W tytule przelewu proszę o podanie następujących danych:
          <br />
          1) imię matki szczeniaka,
          <br />
          2) płeć szczeniaka,
          <br />
          3) telefon kontaktowy.
        </p>
      </div>
      <div className="footer-container-box">
        <h2>Przydatne linki:</h2>
        <ul>
          <li>
            <a
              href="http://web.archive.org/web/20201230015457/http://isap.sejm.gov.pl/isap.nsf/download.xsp/WDU19971110724/U/D19970724Lj.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ustawa o ochronie zwierząt
            </a>
          </li>
          <li>
            <a
              href="http://web.archive.org/web/20201230015457/https://www.olx.pl/oferty/uzytkownik/SrW1/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Konto OLX
            </a>
          </li>
          <li>
            <a
              href="http://web.archive.org/web/20201230015457/http://www.zhpr.pl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Strona ZHPR
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);
