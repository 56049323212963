import { Footer, Map, Navigation } from "../../components";
import { BackgroundType, Button, ContentSection } from "../../ui";

export const GermanShephred = () => (
  <>
    <Navigation />
    <ContentSection
      label="Jaki powinien być owczarek niemiecki?"
      background={BackgroundType.White}
      hasMargin={true}
      content={`<p>Walory psychiczne owczarka które czynią z niego wszechstronnie użytkowego, pasterskiego, 
        obronnego, ratowniczego i tropiącego psa zyskują mu stale nowych zwolenników. Jest też psem o niezwykłej 
        urodzie: harmonijnej, silnej budowie ciała, nie wymagającej sztucznych zabiegów upiększających. 
        Owczarek jest jak najbardziej naturalny. Cechy jakie wyróżniają owczarka to  odwaga, inteligencja,
        bezgraniczne oddanie, wierność oraz posłuszeństwo swojemu właścicielowi. Jest zawsze czujny, 
        gotowy do obrony swojego opiekuna przed każdym, najmniejszym zagrożeniem, nawet jeśli nie miał w
        tym kierunku żadnego przeszkolenia. Ma najlepszy węch spośród wszystkich psów, dlatego jest 
        niezrównanym tropicielem, niezawodnym w odnajdywaniu przestępców, 
        dlatego też upodobały go sobie wszelkie służby.</p>
        <b>Kilka podstawowych informacji, z którymi warto się zapoznać:</b><br><br>
            <b>Nazwa rasy</b> - Deutscher Schäferhund/German shepherd/Owczarek niemiecki.<br>
            <b>Wzrost</b>  - 60–65 cm (pies), 55–60 cm (suka).<br>
            <b>Waga</b>  - 30–40 kg (pies), 22–30 kg (suka).<br>
            <b>Głowa</b>  w kształcie klina, sucha, nie za ciężka i nie za bardzo wydłużona, proporcjonalna do wzrostu. Wierzchołek nosa czarny, wargi stykające się, napięte, ciemnego koloru. Oczy w kształcie migdałów, średniej wielkości, nie wypukłe, możliwie w jak najciemniejszym kolorze<br>
            <b>Uszy</b>  - stojące, proste, średniej wielkości. Małżowina stroną wewnętrzną zwrócona jest ku przodowi.<br>
            <b>Ogon</b>  opuszczony, lekko wygięty ku górze, sięga co najmniej stawu skokowego.<br>
            <b>Szata</b>  - włos okrywowy bardzo gęsty, szorstki, ściśle przylegający do ciała. Krótszy na głowie, kończynach i palcach, dłuższy na szyi.<br>
            <b>Umaszczenie</b>  - czarne ze znaczeniami brązowo-rudymi, brązowymi, żółtymi aż do jasnoszarych lub jednolite.<br>
        `}
    />
    <div className="booked-dog-section">
      <h2>Czujesz, że jesteś gotowy na szczeniaka?</h2>
      <Button label="Zarezerwuj szczeniaka" path="/szczeniaki" />
    </div>
    <Map />
    <Footer />
  </>
);

export default GermanShephred;
