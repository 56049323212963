interface NavigationLinks {
  label: string;
  path: string;
}

export const navigationLinks: NavigationLinks[] = [
  { label: "start", path: "/" },
  { label: "hodowla", path: "/hodowla-z-ksiazenickiego-wzgorza" },
  { label: "galeria", path: "/galeria-psow-z-ksiazenickiego-wzgorza" },
  { label: "rasa", path: "/o-rasie-owczarek-niemiecki" },
  {
    label: "szczeniaki",
    path: "/szczeniaki-owczarka-niemieckiego-na-sprzedaz-z-kiazenickiego-wzgorza",
  },
  { label: "kontakt", path: "/kontakt" },
];
