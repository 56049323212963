interface Dog {
  dogName: string;
  parent: string;
  mainImage: string;
  galleryPath: string;
}

export const Dogs: Dog[] = [
  {
    dogName: "Costa",
    galleryPath: "https://photos.app.goo.gl/7utuFpJ72gSrA8cy6",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHdb4NJGv512Z9Hj1DaB6v-ZtBZqtcr-kHKTn0EltLqBV_sqKSsvJjB4SMHjeg6qT3oXK2mJu-9FzQNeggtJCwzINdta8s6Duptt0cd0YrRU-N8dVen9jjegtUuCY1ZKHeIo-CK8sU1zYsb5Jh81tCM=w900-h506-s-no?authuser=0",
    parent: "Córka mistrza świata Zampa v. Thermodos",
  },
  {
    dogName: "Jasmina",
    galleryPath: "https://photos.app.goo.gl/miqTdFXa2ayYAJjR6",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHe1zjTIFozzEnDhW4YV10kjXH4fDegrroD-LtnTxRQKI9DE9bvsU6Kz2gPpXFM3u6oMXFvXQt5pzgEguQfyKhZD1O1366y6_TQYnHLF3AfyjEkFJ-Q2ZXxta3uX8Q3856_uT5xDexyEbZJVnLaJ0pU=w1350-h894-s-no?authuser=0",
    parent: "Wnuczka mistrza świata Zamp vom Thermodos",
  },
  {
    dogName: "Czad",
    galleryPath: "https://photos.app.goo.gl/fHawsgN6Vu6UN8WCA",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHfeciJ3n5v4JmGbKBQUs7kCAgxsIzp3e1_wMG6tAHcEHJQk4RK7nHLeC-7BdC8NhfB8IBzSEgz1n9rd8GXmBJ8Fp_Cz1xt6KeXlfh0QvyKgWVVIzsZK16MPtJ1tCmbAyOtbQJVPbZB8xHpT-le4BBvy=w1578-h894-s-no?authuser=0",
    parent: "Wnuk wicemistrzyni świata NATHALIe von Alcudia",
  },
  {
    dogName: "Hera",
    galleryPath: "https://photos.app.goo.gl/xBHw6TwmWJXXs6mP9",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHf-BBId3QvtHVUmg9_ON1xnO94eT40lEdez2tOVTXMHRyLhbeyHmgyiihgq2QmAYClWmTFEyq3_tA4LGolko2bMcuZ7Jc67WdiJUStQBg7v2F4rDlOKVPKSHNOON-9MB5h9X1nHsDAkm1bECcsYpmk=w1350-h894-s-no?authuser=0",
    parent: "Wnuczka wicemistrzyni świata NATHALIe von Alcudia",
  },
  {
    dogName: "Grimi",
    galleryPath: "https://photos.app.goo.gl/DLhTfPA7JTUaJEQTA",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHcRgZ-__eXGJnVf8eNctzgTVpolsbO6b2OF4pJB-KgWNNxtGhZiE9DCXoQ6cstvpOWay8urGbuDLyPToTb_853LED5SVs8mED86nZhUQFa_go8137i-BEXj1P94nlnVrUGgWwm8OLKNVYgpasdiXJc=w1350-h894-s-no?authuser=0",
    parent: "Wnuczka v-ce mistrza świata ODIN v. Holtkamper Hof",
  },
  {
    dogName: "Wilma",
    galleryPath: "https://photos.app.goo.gl/njvXip1APgvHumtp9",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHcXzA0LF2wbOQXyPiOVWu48UlveOYGw2O-rUqBD9QcL5uEfoed3f9MUWObn-KSaaSFDx9uNXlC8AqvvoFR5LVkgEQXnhG4GWJnLZC6ojUUMyrlTrpJ4G_-qAPulL0d2DpioB3AqFsK8wUW6cCX0Rgo=w960-h636-s-no?authuser=0",
    parent:
      "Córka wicemistrzyni świata NATHALIe von Alcudia oraz OMENA vom Radhausa- 5-tego psa Świata.",
  },
  {
    dogName: "Zeus",
    galleryPath: "https://photos.app.goo.gl/WGpgxsMgTW5DWDM56",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHfKdt1RhHzSC9BxejPa7_YUIxZZJyvhgc4hQjv1MfZED-2b0T2XzIJ1eun4-jNK8JR7KQaMZ6ppdix9Nyn5sRjW5jdTJsWRBvE6oCGQcqp1Qfc_4JW2pcxtX2cgD0xrnCprnK0NevjQ2hhRrYB2GeA=w900-h507-s-no?authuser=0",
    parent: "Wnuk mistrza świata Zamp vom Thermodos",
  },
  {
    dogName: "Mars",
    galleryPath: "https://photos.app.goo.gl/CfNQmeRsQcTQ76R87",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHeEcS56KEXZErYjRbnWpfRbWhYbDqZSIsLpgoHOJtfsOyZecGlbcjQ3aXYnat38T7ykRY6HL6Gh5-zNzg10Uy4eizYZUFmMgcJWgeg7NZ-PcYKNRHNv1V3wlVv5XhBxMwL4yek1Uu3z1QDDbO-F130=w1937-h1291-s-no?authuser=0",
    parent: "Wnuk wicemistrza świata ODIN von Holtkamper Hof.",
  },
  {
    dogName: "Xantos",
    galleryPath: "https://photos.app.goo.gl/dKuWX6FusQdvsuZJA",
    mainImage:
      "https://lh3.googleusercontent.com/pw/ADCreHcPynm65tZnR3mYQa2sAFl0p02-xVRjimy1RQlVZ0syuQhPEveVd8bY_ZZ_WPcBaRrBZ6HF_GyteK4W6UQZLGAuzEi9kkl_oCU9IPdj6A-M5SSJR9CN_1eUSvWRieUOlm7kZCp1E7nJaj90cKkd4U4=w960-h541-s-no?authuser=0",
    parent:
      "Ojciec Kwantum Klostermoor, zdobywcy czwartego miejsca na wystawie światowej w 2009 roku.",
  },
];
