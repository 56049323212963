import { Footer, Map, Navigation } from "../../components";
import { BackgroundType, ContentSection } from "../../ui";
import "./contact.css";

export const Contact = () => {
  return (
    <>
      <Navigation />
      <ContentSection
        label="O hodowli"
        hasMargin={true}
        background={BackgroundType.White}
        content={`        <ul class="contact-list">
          <li><b>Telefon:</b><br> 516 052 576, 32 42 19 725</li>
          <li><b>Adres:</b><br> ul. Klimka 34, 44-213 Książenice</li>
          <li>
            <b>Email:</b><br>
            <a href="mailto:mkondzielnik@poczta.onet.pl">
              mkondzielnik@poczta.onet.pl
            </a>
          </li>
          <li>
            <b>Facebook:</b><br>
            <a
              href="https://www.facebook.com/zksiazenickiegowzgorza"
              target="_blank"
              rel="noopener noreferrer"
            >
              zksiazenickiegowzgorza
            </a>
          </li>
        </ul>`}
      />
      <Map />
      <Footer />
    </>
  );
};

export default Contact;
