import { Footer, Map, Navigation } from "../../components";
import { BackgroundType, ContentSection } from "../../ui";
import "./puppies.css";

export const Puppies = () => {
  return (
    <>
      <Navigation />
      <section className="puppies-section puppies-section-margin">
        <h2>Aktualne szczeniaki na sprzedaż</h2>
      </section>
      <ContentSection
        background={BackgroundType.White}
        label="Hera i Mars - 26.09.2023"
        hasMargin={false}
        content="
        <p>26.09.2023 roku w naszej hodowli pojawiły się szczeniaki z miotu Marsa - wnuka wicemistrza świata ODIN von Holtkamper Hof oraz Hery - Wnuczki wicemistrzyni świata NATHALIe von Alcudia</p>
        <p>Oboje rodziców stanowią doskonały przykład idealnego przedstawiciela owczarka niemieckiego długowłosego. Są przyjazne i pewne siebie bez oznak agresji i lękliwości.</p>
        <p>Zarówno rodzice jak i szczeniaki są świetnymi okazami zdrowia, przebywają pod stałą opieką weterynaryjną dlatego nie ma mowy o żadnym odejściu od typu owczarka.</p>
        <p>Rodzice posiadają zrównoważony charakter, ciemne umaszczenie i mocne kości. </p>
        <p>Szczenięta opuszczające naszą hodowlę otrzymują rodowód ZHPR oraz książeczkę zdrowia.</p>
        <p>Jako hodowla możemy zagwarantować, że będziemy z Państwem w stałym kontakcie oraz będziemy służyli wszelkimi radami.</p>
        <p>W celu jakichkolwiek pytań bądz rezerwacji szczeniaka prosimy o kontakt pod numerem 516-052-576 lub 32-42-19-725. Z chęcią udzielimy wszelkich niezbędnych informacji.</p>
        <p><b>Cena szczeniaka:</b> 1400 zł<br><b>Przewidywany odbiór:</b> 14 Listopada 2023 roku.</p>"
      />
      <Map />
      <Footer />
    </>
  );
};

export default Puppies;
