import { Dogs } from "./gallery.data";
import "./gallery.css";

export const Gallery = () => (
  <div className="gallery">
    {Dogs.map((item, index) => (
      <a
        href={item.galleryPath}
        className="gallery-item"
        key={index}
        target="_blank"
      >
        <div className="gallery-item-image">
          <img src={item.mainImage} alt={item.dogName} />
        </div>
        <h3>{item.dogName}</h3>
        <p>{item.parent}</p>
      </a>
    ))}
  </div>
);

export default Gallery;
