import "./button.css";

interface ButtonProps {
  label: string;
  path: string;
}

export const Button = ({ label, path }: ButtonProps) => (
  <a className="button" href={path}>
    {label}
  </a>
);
