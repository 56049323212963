import { Footer, Map, Navigation } from "../../components";
import { BackgroundType, Button, ContentSection } from "../../ui";

export const About = () => {
  return (
    <>
      <Navigation />
      <ContentSection
        label="O hodowli"
        hasMargin={true}
        background={BackgroundType.White}
        content={`<p>Od dziecka interesowałem się psami. Moim marzeniem było mieć pięknego
        owczarka niemieckiego. Pierwszego owczarka, sukę z rodowodem, kupiłem w
        1995 roku. Suka wabiła się Diana. Jest inteligentna, ma twardy
        charakter. Na podstawie obserwacji zachowań Diany doszedłem do wniosku,
        że owczarki niemieckie to jedne z najinteligentniejszych psów. Z czasem
        w wyniku zafascynowania rasą postanowiłem założyć hodowlę własną hodowlę
        by móc uszczęśliwiać ludzi przekazując im jak najwspanialsze, wierne,
        oddane i inteligentne szczeniaki. Wstąpiłem do związku kynologicznego,
        gdzie moja hodowla otrzymała przydomek "Z Książenickiego Wzgórza".
        Poznałem tam wielu znakomitych hodowców i nabierałem od nich cennego
        doświadczenia w hodowaniu psów, które pozwala mi w tym momencie na
        uzyskiwanie zdrowych, silnych psów o nienagannym rodowodzie, które
        towarzyszom moim klientom przez całe swoje życie.</p>`}
      />
      <div className="booked-dog-section">
        <h2>Czujesz, że jesteś gotowy na szczeniaka?</h2>
        <Button label="Zarezerwuj szczeniaka" path="/szczeniaki" />
      </div>
      <Map />
      <Footer />
    </>
  );
};

export default About;
